<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Calendar')" />
    <div class="tw_container">
      <div v-if="searchForm.mode === 'WEEK' || searchForm.mode === 'SEARCH'" class="mini_calendar">
        <div class="head">
          <h3>{{searchForm.selectMonth | dateFormatMonth}}</h3>
          <div class="btn_arrow">
            <i class="back" @click="prevMonth" />
            <i class="next" @click="nextMonth" />
          </div>
        </div>
        <el-calendar :first-day-of-week="7" :value="searchForm.displayMonth">
          <template
            slot="dateCell"
            slot-scope="{date}">
            <span class="date">
              {{ date | dayFormat }}
            </span>
            <span v-if="events(date).length" class="dot" />
          </template>
        </el-calendar>
      </div>
      <div class="scroller" :class="[searchForm.mode.toLowerCase()]">
        <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />
        <template v-if="searchForm.mode === 'SEARCH'">
          <div class="back" @click="back">
            <img src="@/assets/images/icons/chevron_left_gray800.svg">Back
          </div>
          <tw-table-system style="margin-top: 40px;" :schemas="schemas" :items="tradingFlowList" @row-click="rowClick" @row-dbclick="rowDbClick" :nopager="true" />
          <div style="height: 44px" />
        </template>

        <template v-else>
          <el-form class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
            <div class="row">
              <el-form-item :label="$t('Label.Owner')" prop="ownerSectionIds">
                <el-select v-model="searchForm.ownerSectionIds" class="section" :class="{multiple_selected: multipleSelect(searchForm.ownerSectionIds)}" placeholder="Select" multiple collapse-tags clearable>
                  <el-option
                    v-for="section in ownerSectionLists"
                    :key="section.sectionId"
                    :label="section.sectionShortName"
                    :value="section.sectionId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
                <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
              </el-form-item>
              <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
                <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
              </el-form-item>
              <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
                <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
                <el-input type="text" v-model="searchForm.flowName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Process')" prop="process">
                <el-cascader popper-class="category-select" v-model="searchForm.process" placeholder="Select" :options="processes" :props="{value: 'code', 'label': 'name'}" :filter-method="filter" clearable filterable>
                  <template slot-scope="{ data }">
                    <div style="display: flex;align-items: center">
                      <tw-entity-icon v-if="data.entityId" :entityName="data.entityId" size="medium" color="color" style="margin-right: 8px;" />
                      <span>{{data.name}}</span>
                    </div>
                  </template>
                </el-cascader>
              </el-form-item>
              <div>
                <tw-button
                  type="secondary"
                  size="medium"
                  icon="search"
                  @click="search"
                >
                  Search
                </tw-button>
                <tw-button
                  type="default"
                  size="medium"
                  @click="clear"
                  style="margin-top: 23.5px;"
                >
                  Clear
                </tw-button>
              </div>
            </div>
          </el-form>

          <div class="view_options">
            <div v-if="searchForm.mode === 'WEEK'" class="set_term">
              <div class="btn_arrow">
                <i class="back" @click="prevWeek" />
                <i class="next" @click="nextWeek" />
              </div>
            </div>

            <div v-else-if="searchForm.mode === 'MONTH'" class="set_term">
              <div class="btn_arrow">
                <i class="back" @click="prevMonth" />
                <i class="next" @click="nextMonth" />
              </div>
              <el-select class="term" v-model="searchForm.selectMonth" size="small" placeholder="Select" @change="changeMonth()">
                <el-option
                  v-for="month in months"
                  :key="month.value"
                  :label="month.label"
                  :value="month.value">
                </el-option>
              </el-select>
            </div>

            <div class="calendar_tabs">
              <div class="tab" :class="{active: searchForm.mode === 'WEEK'}" @click="changeCalendar('WEEK')">Week</div>
              <div class="tab" :class="{active: searchForm.mode === 'MONTH'}" @click="changeCalendar('MONTH')">Month</div>
            </div>
          </div>

          <div class="right_menu">
            <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
              <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
              <el-dropdown-menu class="nowrap">
                <el-dropdown-item command="reminder">{{$t('Label.Create Reminder')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <el-calendar v-if="searchForm.mode === 'MONTH'" :first-day-of-week="7" :value="searchForm.displayMonth">
            <template
              slot="dateCell"
              slot-scope="{date}">
              <span class="date">
                {{ date | dayFormat }}
              </span>
              <template v-if="events(date).length">
                <el-dropdown v-for="(event, index) in events(date).slice(0, eventSliceLength(events(date)))" :key="index" style="width: 100%;">
                  <template v-if="event.processId">
                    <div class="event" :class="entityClass(event.entityProgressStatusCd)">
                      <tw-alert-icon :limit="event.calculatedDueDate" :processProgressStatus="event.processProgressStatusCd" type="color" size="small" />
                      <tw-entity-icon size="ex_small" :entityName="getEntityName(event.entityTypeCd)" />
                      <span class="process" :class="{hasAlert: hasAlert(event.calculatedDueDate, event.processProgressStatusCd)}">{{getProcessName(event.processId)}}</span>
                    </div>
                    <el-dropdown-menu class="event_dropdown">
                      <div class="event_detail" :class="entityClass(event.entityProgressStatusCd)" @click="showProcessDetail(event)">
                        <div class="inner">
                          <div class="icons">
                            <tw-alert-icon :limit="event.calculatedDueDate" :processProgressStatus="event.processProgressStatusCd" type="color" />
                            <tw-entity-icon :entityName="getEntityName(event.entityTypeCd)" />
                          </div>
                          <span class="process">{{getProcessName(event.processId)}}</span>
                          {{`${event.tradingName}（${event.tradingManageName}）`}}<br>
                          {{getDisplayNo(event)}}<br>
                        </div>
                      </div>
                    </el-dropdown-menu>
                  </template>
                  <template v-else>
                    <div class="event reminder">
                      <i/>
                      <time v-if="event.reminderTime">{{event.reminderTime | timeFormatShort}}</time>
                      <span class="process">{{event.reminderTitle}}</span>
                    </div>
                    <el-dropdown-menu class="event_dropdown">
                      <div class="event_detail reminder" @click="showReminder(event)">
                        <div class="inner">
                          <div class="icons">
                            <i/>
                            <time>{{event.reminderTime | timeFormatShort}}</time>
                          </div>
                          <span class="process">{{event.reminderTitle}}</span>
                          <span class="reminder_text" v-html="sanitize(event.reminderText)" />
                        </div>
                      </div>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <div v-if="events(date).length > 3" class="more_events">
                  <span  @click="showMoreEvents(date)">{{events(date).length - 2}} More</span>
                </div>
              </template>
            </template>
          </el-calendar>

          <div v-else-if="searchForm.mode === 'WEEK'" class="calendar_week">
            <div v-for="(day, index) in week" class="week_detail" :key="index" :class="{'is-today': isToday(day)}">
              <div class="week_date">
                {{day | dayOfWeekFormat}}
                <span>{{day | dayLocalFormat}}</span>
              </div>
              <div class="trading_list">
                <template v-for="event in weekEvents(day)">
                  <!-- eslint-disable-next-line vue/require-v-for-key -->
                  <div v-if="event.processId" class="event" :class="entityClass(event.entityProgressStatusCd)" @click="showProcessDetail(event)">
                    <div class="inner">
                      <div class="icons">
                        <tw-alert-icon :limit="event.calculatedDueDate" :processProgressStatus="event.processProgressStatusCd" type="color" />
                        <tw-entity-icon :entityName="getEntityName(event.entityTypeCd)" />
                      </div>
                      <span class="process">{{getProcessName(event.processId)}}</span>
                      {{`${event.tradingName}（${event.tradingManageName}）`}}<br>
                      {{getDisplayNo(event)}}<br>
                    </div>
                  </div>
                  <!-- eslint-disable-next-line vue/require-v-for-key -->
                  <div v-else class="event reminder" @click="showReminder(event)">
                    <div class="inner">
                      <div class="icons">
                        <i/>
                        <time>{{event.reminderTime | timeFormatShort}}</time>
                      </div>
                      <span class="process">{{event.reminderTitle}}</span>
                      <span class="reminder_text" v-html="sanitize(event.reminderText)" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div style="height: 44px" />
        </template>
      </div>
    </div>
     <tw-drawer :close-confirm="true" @close="closeConfirm" >
      <template slot>
        <tw-new-reminder v-if="drawerShow" :data="reminder" @update-calendar="fetch" />
      </template>
    </tw-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { CALENDAR_USE_CODE, ENTITY_PROGRESS_STATUS_VARIABLES, PROCESS_PROGRESS_STATUS } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { entityNames, processNames, } from '@/dictionaries/map.js';
import { formatResponseDate, normalize } from '@/utils/searchUtil';
import TwAlertIcon from '@/components/atoms/TwAlertIcon';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwNewReminder from '@/components/templates/TwNewReminder';
import schemas from '@/dictionaries/calendarSearchResultSchema.json';

export default {
  name: 'TradingListCalendar',
  mixins: [mixinEntityList],
  props: {
    history: Object,
    displayDateStart: [String, Date],
    displayDateEnd: [String, Date],
    dashboardSectionId: Array,
    reminderData: Object,
  },
  components: {
    TwAlertIcon,
    TwEntityIcon,
    TwDrawer,
    TwNewReminder
  },
  data() {
    return {
      activeTab: 2,
      schemas: schemas,
      addTradingResponse: null,
      categories: _.map(entityNames, o => {
        return {
          ...o,
          name: this.$t(`Entity.${o.nameEn}`)
        };
      }),
      // プロセスプルダウン
      processes: [],
      searchForm: {
        ownerSectionIds: [],
        fromSectionIds: [],
        toSectionIds: [],
        tradingName: '',
        flowName: '',
        process: [],
        mode: 'MONTH',
        prevMode: 'MONTH',
        displayMonth: dayjs().startOf('month').toDate(),
        displayDateStart: dayjs().startOf('month').startOf('week').toDate(),
        displayDateEnd: dayjs().endOf('month').endOf('week').toDate(),
        displayWeekStart: dayjs().startOf('week').toDate(),
        displayWeekEnd: dayjs().endOf('week').toDate(),
        selectMonth: dayjs().startOf('month').format(),
      },
      // 月選択プルダウン
      months: this.createMonths(dayjs().year()),
      tradingFlowList: null,
      calendarReminders: [],
      reminder: {
        reminderId: null,
        reminderDate: null,
        reminderTime: null,
        reminderTitle: '',
        reminderText: '',
      }
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Calendar')},
      ];
    },
    ownerSectionLists() {
      return this.$store.getters.getOwnerSectionLists;
    },
    week() {
      const week = _.reduce(_.range(0, 7), (ary, n) => {
        const date = dayjs(this.searchForm.displayWeekStart).add(n, 'day').toDate();
        ary.push(date);
        return ary;
      }, []);
      return week;
    },
    isToday() {
      return date => {
        return dayjs(date).isToday();
      }
    },
    drawerShow() {
      return this.$store.state.drawerShow
    },
    entityClass() {
      return entityProgressStatusCd => {
        return _.snakeCase(_.get(_.find(ENTITY_PROGRESS_STATUS_VARIABLES, {code: entityProgressStatusCd}), 'label'));
      };
    },
    getEntityName() {
      return entityTypeCd => {
        return _.get(_.find(entityNames, {code: entityTypeCd}), 'entityId');
      };
    },
    getProcessName() {
      return processId => {
        return _.get(processNames[processId.replace(/\d/g, '')], 'nameShort');
      };
    },
    events() {
      return date => {
        const reminders = _.filter(this.calendarReminders, o => {
          if (!o.reminderDate) {
            return false;
          }
          return dayjs(o.reminderDate.slice(0, 10)).isSame(date, 'day');
        });
        const events =  _.filter(this.tradingFlowList, o => {
          if (!o.calculatedDueDate) {
            return false;
          }
          return dayjs(o.calculatedDueDate.slice(0, 10)).isSame(date, 'day');
        });
        return [...reminders, ...events];
      }
    },
    eventSliceLength() {
      return events => {
        if (events.length > 3) {
          return 2;
        } else {
          return 3;
        }
      }
    },
    weekEvents() {
      return date => {
        const reminders = _.filter(this.calendarReminders, o => {
          return dayjs(o.reminderDate.slice(0, 10)).isSame(date, 'day');
        });
        const events = _.filter(this.tradingFlowList, o => {
          return dayjs(o.calculatedDueDate.slice(0, 10)).isSame(date, 'day');
        });
        return [...reminders, ...events];
      }
    },
    getDisplayNo() {
      return event => {
        return event.invoiceNo || event.contractNo || '';
      }
    },
    hasAlert() {
      return (_limit, processProgressStatusCd) => {
        if (_limit || _.isNaN(processProgressStatusCd)) {
          return false;
        }
        // 完了時は表示しない
        if ([PROCESS_PROGRESS_STATUS.AGREED, PROCESS_PROGRESS_STATUS.DONE].includes(processProgressStatusCd)) {
          return false;
        }
        const limit = dayjs(formatResponseDate(_limit));
        if (limit.isToday() || limit.isYesterday()) {
          return true;
        }
        if (dayjs().isSameOrAfter(limit, 'day')) {
          return true;
        }
        return false;
      }
    },
    isPic() {
      // ユーザーロルが担当者か
      return this.$store.getters.isPic;
    },
  },
  created() {
    if (_.isEmpty(this.ownerSectionLists)) {
      this.$store.dispatch('GET_OWNER_SECTIONS');
    }
    this.processes = this.getProcesses();
    const queries = this.$store.getters.getQueries('TRADING_LIST_CALENDAR');
    if (queries && queries.mode) {
      this.searchForm = queries;
      this.searchForm.selectMonth = dayjs(this.searchForm.selectMonth).startOf('month').format();
    }

    if (this.displayDateStart) {
      // ダッシュボードカレンダーからの遷移時
      this.searchForm.displayMonth = dayjs(this.displayDateStart).startOf('month').toDate();
      this.setDateParams();
      if (dayjs(this.displayDateStart).endOf('week').month() !== dayjs(this.searchForm.displayMonth).month()) {
        // ダッシュボードのdisplayDateStartの週の終わりの月が週初めの月と変わっていたら
        this.nextMonth();
      }
      if (this.searchForm.mode === 'WEEK') {
        // 週表示の時
        this.searchForm.displayWeekStart = dayjs(this.displayDateStart).toDate();
        this.searchForm.displayWeekEnd = dayjs(this.searchForm.displayWeekStart).endOf('week').toDate();
      }

      this.searchForm.ownerSectionIds = _.filter(this.dashboardSectionId, sectionId => {
        return _.find(this.ownerSectionLists, {sectionId: sectionId});
      });
      this.searchForm.fromSectionIds = this.searchForm.toSectionIds = this.dashboardSectionId;
    }

    this.months = this.createMonths(dayjs(this.searchForm.displayMonth).year());

    this.fetch();
  },
  mounted() {
    if (this.reminderData) {
      this.showReminder(this.reminderData);
    }
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        ownerSectionId: f.ownerSectionIds,
        fromSectionId: f.fromSectionIds,
        toSectionId: f.toSectionIds,
        tradingName: f.tradingName,
        tradingFlowName: f.flowName,
        processType: _.get(f.process, '1'),
      };

      return {
        ...queries,
        initialFlag: this.initialFlag(queries),
        displayDateStart: dayjs(f.displayDateStart).subtract(3, 'day').format('YYYY-MM-DD'),
        displayDateEnd: dayjs(f.displayDateStart).endOf('week').add(5, 'week').add(3, 'day').format('YYYY-MM-DD'),
        calendarUseCode: CALENDAR_USE_CODE.TRADING_LIST,
      };
    },
    fetch() {
      const params = {
        // bff_db_2 ダッシュボードカレンダー取得BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/dashboard/calendar',
          query: this.transformQueries(),
        }
      };

      $api.request(params)
      .then(res => {
        this.tradingFlowList = _.orderBy( _.filter(res.tradingFlowList, o => {
          return o.calculatedDueDate && dayjs(o.calculatedDueDate.slice(0, 10)).isBetween(this.searchForm.displayDateStart, this.searchForm.displayDateEnd, null, '[]');
        }), ['calculatedDueDate'], ['asc']);
        this.calendarReminders = res.calendarReminders;
        if (!_.isEmpty(res.ownerSectionLists)) {
          this.$store.commit('SET_OWNER_SECTION_LISTS', _.map(res.ownerSectionLists, s => {
            return {
              ...s,
              label: s.sectionShortName,
              value: s.sectionId,
            }
          }));
        }
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      this.tradingFlowList = null;
      this.searchForm.prevmode = this.searchForm.mode;
      this.searchForm.mode = 'SEARCH';
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(this.searchForm)});
      this.tradingFlowList = null;
      this.fetch();
    },
    clear() {
      this.searchForm = {
        ...this.searchForm,
        ownerSectionIds: [],
        fromSectionIds: [],
        toSectionIds: [],
        tradingName: '',
        flowName: '',
        process: [],
      };
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(this.searchForm)});
    },
    back() {
      this.searchForm.mode = this.searchForm.prevmode;
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(this.searchForm)});
    },
    // カレンダーのタブ切り替え
    changeCalendar(mode) {
      if (this.searchForm.mode === 'MONTH' && mode === 'WEEK') {
        this.setWeek();
      }
      this.searchForm.mode = mode;
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    // 週表示の表示週をセット
    setWeek() {
      if (dayjs(this.searchForm.displayMonth).isSame(dayjs(), 'month')) {
        // 当月の場合は当日週を週表示
        this.searchForm.displayWeekStart = dayjs().startOf('week').toDate();
        this.searchForm.displayWeekEnd = dayjs().endOf('week').toDate();
      } else {
        this.searchForm.displayWeekStart = dayjs(this.searchForm.displayMonth).startOf('week').toDate();
        this.searchForm.displayWeekEnd = dayjs(this.searchForm.displayMonth).endOf('week').toDate();
      }
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(this.searchForm)});
    },
    // 日付周りのパラメータをセット
    setDateParams() {
      this.searchForm.displayDateStart = dayjs(this.searchForm.displayMonth).startOf('month').startOf('week').toDate();
      this.searchForm.displayDateEnd = dayjs(this.searchForm.displayMonth).endOf('month').endOf('week').toDate();
      this.fetch();
      this.months = this.createMonths(dayjs(this.searchForm.displayMonth).year());
      this.searchForm.selectMonth = dayjs(this.searchForm.displayMonth).startOf('month').format();
      this.setWeek();
    },
    // 前月
    prevMonth() {
      this.searchForm.displayMonth = dayjs(this.searchForm.displayMonth).subtract(1, 'month').toDate();
      this.setDateParams();
    },
    // 翌月
    nextMonth() {
      this.searchForm.displayMonth = dayjs(this.searchForm.displayMonth).add(1, 'month').toDate();
      this.setDateParams();
    },
    // プルダウンから表示月を変更
    changeMonth() {
      this.searchForm.displayMonth = this.searchForm.selectMonth;
      this.searchForm.displayDateStart = dayjs(this.searchForm.displayMonth).startOf('month').startOf('week').toDate();
      this.searchForm.displayDateEnd = dayjs(this.searchForm.displayMonth).endOf('month').endOf('week').toDate();
      this.fetch();
      this.setWeek();
    },
    // 前週
    prevWeek() {
      this.searchForm.displayWeekStart = dayjs(this.searchForm.displayWeekStart).subtract(1, 'week').toDate();
      this.searchForm.displayWeekEnd = dayjs(this.searchForm.displayWeekEnd).subtract(1, 'week').toDate();
      if (!(dayjs(this.searchForm.displayWeekStart).isBetween(this.searchForm.displayDateStart, this.searchForm.displayDateEnd, null, '[]') && dayjs(this.searchForm.displayWeekEnd).isBetween(this.searchForm.displayDateStart, this.searchForm.displayDateEnd, null, '[]'))) {
        // 月が変わった時
        this.searchForm.displayMonth = dayjs(this.searchForm.displayMonth).subtract(1, 'month').toDate();
        this.searchForm.displayDateStart = dayjs(this.searchForm.displayMonth).startOf('month').startOf('week').toDate();
        this.searchForm.displayDateEnd = dayjs(this.searchForm.displayMonth).endOf('month').endOf('week').toDate();
        this.fetch();
        this.months = this.createMonths(dayjs(this.searchForm.displayMonth).year());
        this.searchForm.selectMonth = dayjs(this.searchForm.displayMonth).startOf('month').format();
      } else {
        this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(this.searchForm)});
      }
    },
    // 翌週
    nextWeek() {
      this.searchForm.displayWeekStart = dayjs(this.searchForm.displayWeekStart).add(1, 'week').toDate();
      this.searchForm.displayWeekEnd = dayjs(this.searchForm.displayWeekEnd).add(1, 'week').toDate();
      if (!(dayjs(this.searchForm.displayWeekStart).isBetween(this.searchForm.displayDateStart, this.searchForm.displayDateEnd, null, '[]') && dayjs(this.searchForm.displayWeekEnd).isBetween(this.searchForm.displayDateStart, this.searchForm.displayDateEnd, null, '[]'))) {
        // 月が変わった時
        // this.nextMonth();
        this.searchForm.displayMonth = dayjs(this.searchForm.displayMonth).add(1, 'month').toDate();
        this.searchForm.displayDateStart = dayjs(this.searchForm.displayMonth).startOf('month').startOf('week').toDate();
        this.searchForm.displayDateEnd = dayjs(this.searchForm.displayMonth).endOf('month').endOf('week').toDate();
        this.fetch();
        this.months = this.createMonths(dayjs(this.searchForm.displayMonth).year());
        this.searchForm.selectMonth = dayjs(this.searchForm.displayMonth).startOf('month').format();
      } else {
        this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(this.searchForm)});
      }
    },
    filter(node, keyword) {
      const keywords = node.pathNodes.reduce((ary, pathNode) => {
        if (Array.isArray(pathNode.data.keywords)) {
          return ary.concat(node.pathLabels).concat(pathNode.data.keywords);
        }
        return ary.concat(node.pathLabels);
      }, []);
      return keywords.some((str) => normalize(str).includes(normalize(keyword)));
    },
    createMonths(year) {
      const months = _.reduce(_.range(0, 12), (ary, n) => {
        const month = dayjs().startOf('year').year(year).month(n);
        ary.push({
          label: month.format('YYYY MMM'),
          value: month.format(),
        });
        return ary;
      }, []);
      return months;
    },
    showProcessDetail(event) {
      this.$router.push({
        name: `Process${_.capitalize(event.processId.replace(/\d/g, ''))}`,
        params: { entityId: event.entityId, processSeq: event.processSeq, _processId: event.processId.toLowerCase() },
      });
    },
    // イベントが三件以上あるときに週表示に切り替えます
    showMoreEvents(date) {
      this.searchForm.displayWeekStart = dayjs(date).startOf('week').toDate();
      this.searchForm.displayWeekEnd = dayjs(date).endOf('week').toDate();
      this.searchForm.mode = 'WEEK';
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST_CALENDAR', queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    // リマインダードロワー表示（新規）
    onDropDownClick(command) {
      if (command === 'reminder') {
        this.showReminder({
          reminderId: null,
          reminderDate: null,
          reminderTime: null,
          reminderTitle: '',
          reminderText: '',
        });
      }
    },
    // リマインダードロワー表示（詳細）
    showReminder(data) {
      this.reminder = data;
      this.$store.commit('SET_DRAWER', true);
    },
    // ドロワーを閉じるときの確認
    closeConfirm() {
      this.$store.dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
      .then(() => {
        this.$store.commit('SET_DRAWER', false);
        this.reminder = {
          reminderId: null,
          reminderDate: null,
          reminderTime: null,
          reminderTitle: '',
          reminderText: '',
        };
      })
      .catch(() => {});
    },
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_container {
    display: flex;
    padding: 0;

    .mini_calendar {
      position: sticky;
      width: 300px;
      height: 100%;
      top: 0;
      background: $color_gray_100;
      flex-shrink: 0;
      padding: 88px 16px 0;
    }

    .scroller {
      width: 100%;
      height: auto;
      padding: 11px 22px 44px;

      &.week, &.search {
        width: calc(100% - 300px);
        padding-left: 24px;
      }
    }
  }

  .search_unit {
    margin-bottom: 28px;
  }

  ::v-deep .side_drawer {
    width: 682px;

    .inner {
      padding: 20px 58px 0px 40px;
    }
  }

  ::v-deep .el-calendar {
    border-radius: 6px;
    .el-calendar__header {
      display: none;
    }

    .el-calendar__body {
      padding: 0;
    }

    table {
      width: 100%;
      background: $color_white;
      border-collapse: collapse;
      border-radius: 6px;
      @include card_shadow;

      th {
        height: 28px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        padding: 0;
      }

      td {
        padding: 2px;
        height: 120px;
        background: $color_white;
        border: 1px solid $color_gray_300;
        overflow: hidden;

        .el-calendar-day {
          height: 100%;
          padding: 0;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #252525;
          cursor: default;

          &:hover {
            background: none;
          }
        }

        &.prev .el-calendar-day, &.next .el-calendar-day {
          color: $color_gray_600;
        }

        span.date {
          display: inline-flex;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
        }

        &.is-today span.date {
          color: $color_white;
          background:$color_dark_blue;
        }
      }

      .event {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 29px;
        border-radius: 6px;
        margin-top: 2px;
        padding: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 20.5px;
        cursor: pointer;

        &.not_started {
          color: $color_white;
          background: $color_draft;
          // .event_detail {
          //   background: $color_draft;
          // }
        }

        &.in_progress {
          color: $color_white;
          background: $color_inprogress;
          // .event_detail {
          //   background: $color_inprogress;
          // }
        }

        &.closed {
          color: $color_gray_800;
          background: $color_closed;
          // .event_detail {
          //   background: $color_closed;
          // }
        }

        .tw_alert_icon {
          width: 20px;
          height: 20px;
          margin-right: 4px;
          flex-shrink: 0;
        }

        .tw_entity_icon {
          width: 20px;
          height: 20px;
          margin-right: 4px;
          flex-shrink: 0;
        }

        i {
          display: block;
          width: 20px;
          height: 20px;
          background-color: $color_gray_100;
          border-radius: 100%;
        }

        .process {
          max-width: calc(100% - 24px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &.hasAlert {
            max-width: calc(100% - 48px);
          }
        }

        &.reminder {
          background: $color_dark_blue;
          color: $color_white;
          margin-right: 2px;

          i {
            border-radius: 0;
            background: transparent url(../assets/images/icons/calendar.svg) no-repeat center center / 18px auto;
          }

          time {
            font-weight: normal;
            margin-right: 8px;
            flex-shrink: 0;
          }
        }
      }

      .more_events {
        text-align: right;
        margin-top: 3px;
        line-height: 1;
        span {
          display: inline-flex;
          align-items: center;
          padding-right: 16px;
          background: url(../assets/images/icons/icon_arrow_right_blue.svg) no-repeat right center;
          background-size: 16px auto;
          color: $color_dark_blue;
          font-size: 10px;
          line-height: 16px;
          cursor: pointer;
        }
      }
    }
  }

  .event_detail {
    width: 248px;
    min-height: 106px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $color_gray_600;
    text-align: left;
    border-radius: 6px;
    @include drop_shadow;
    cursor: pointer;

    &.not_started {
      border-left: 6px solid $color_draft;
    }

    &.in_progress {
      border-left: 6px solid $color_inprogress;
    }

    &.closed {
      border-left: 6px solid $color_closed;
    }

    &.reminder {
      border-left: 6px solid $color_dark_blue;
    }

    .inner {
      width: 100%;
      height: 100%;
      padding: 10px 10px 6px 10px;
      background: $color_white;
      border-radius: 0 6px 6px 0;
    }

    .icons{
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .tw_entity_icon {
        background-color: $color_gray_100;
      }
      .tw_alert_icon {
        margin-right: 8px;
      }
    }

    .process {
      display: block;
      color: $color_gray_800;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.reminder {
      word-break: break-all;
      .icons {
        i {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 4px;
          background: url(../assets/images/icons/calendar_blue.svg) no-repeat center center / 18px auto;
        }

      }
      .process {
        margin-top: 4px;
        margin-bottom: 8px;
      }

      .reminder_text {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        max-height: 36px;
        -webkit-box-orient: vertical;
      }
    }
  }

  //週表示
  .calendar_week {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 635px;
    border-radius: 6px;
    margin: 20px 0px;
    padding: 0px;
    background: $color_white;
    @include drop_shadow;

    .week_detail {
      width: calc(100% / 7);
      border-right: 1px solid $color_gray_300;
      &:last-of-type {
        border-right: none;
      }
    }

    .week_date {
      padding: 4px 8px 16px;
      font-size: 10px;
      line-height: 14px;
      color: #71717A;
      border-bottom: 1px solid $color_gray_300;

      span {
        display: block;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        color: $color_gray_800;
      }
    }

    .prev .week_date span, .next .week_date span {
      color: $color_gray_600;
    }

    .trading_list{
      min-height: calc(100% - 68px);
      padding-top: 43px;

      .event {
        display: block;
        height: auto;
        padding: 2px;
        color: $color_gray_600;
        background: $color_white;
        font-size: 12px;
        line-height: 18px;
        background: $color_white;
        cursor: pointer;

        .icons{
          display: flex;
          align-items: center;
          margin-bottom: 4px;

          .tw_entity_icon {
            background-color: $color_gray_100;
          }
          .tw_alert_icon {
            margin-right: 8px;
          }
        }

        &.not_started {
          border-top: 4px solid $color_draft;
        }

        &.in_progress {
          border-top: 4px solid $color_inprogress;
        }

        &.closed {
          border-top: 4px solid $color_closed;
        }

        &.reminder {
          word-break: break-all;
          border-top: 4px solid $color_dark_blue;

          i {
            background: url(../assets/images/icons/calendar_blue.svg) no-repeat center center / 18px auto;
          }
        }

        .inner {
          padding: 6px 2px 22px;
          &:hover{
            background-color: #F2F8FE;
          }
        }

        .process {
          display: block;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: $color_gray_800;
          margin: 8px 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .reminder_text {
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          max-height: 36px;
          -webkit-box-orient: vertical;
        }
      }

    }

    .is-today {
        background-color: $color_gray_100;
      .week_date{
        background-color: $color_dark_blue;
        color: $color_white;
        span {
          color: $color_white;
        }
      }
    }

  }

  //アイコン
  .event {
    i {
      display: inline-block;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin-right: 4px;
      background-size: 20px auto;

      &.contract {
        background: $color_gray_100 url(../assets/images/icons/sales_contract.svg) no-repeat right center / cover;
      }

      &.ex_custom {
        background: $color_gray_100 url(../assets/images/icons/ex_custom.svg) no-repeat right center / cover;
      }

      &.transport {
        background: $color_gray_100 url(../assets/images/icons/transport.svg) no-repeat right center / cover;
      }

      &.insurance {
        background: $color_gray_100 url(../assets/images/icons/insurance.svg) no-repeat right center / cover;
      }

      &.final_docs {
        background: $color_gray_100 url(../assets/images/icons/final_docs.svg) no-repeat right center / cover;
      }

      &.im_custom {
        background: $color_gray_100 url(../assets/images/icons/im_custom.svg) no-repeat right center / cover;
      }
    }
  }


  //表示設定
  .view_options {
    width: calc(100% - 220px); //.set_termの幅を引く
    margin-bottom: 28px;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .right_menu {
    position: relative;
    .el-dropdown {
      position: absolute;
      top: -62px;
      right: 0;
    }
  }

  //表示期間を設定
  .set_term {
    display: flex;
    justify-content: left;
    align-items: center;
    min-width: 220px;
    min-height: 36px;
    // backdrop-filter: blur(70px);

    .btn_arrow {
      display: flex;
      border-radius: 6px;
      @include card_shadow;
    }

    i {
      width: 28px;
      height: 28px;
      margin: 0px 1px;
      padding: 4px;
      box-shadow: none;
      cursor: pointer;
      &.back{
        border-radius: 6px 0px 0px 6px;
        background: url(../assets/images/icons/chevron_left_gray800.svg) $color_white center no-repeat;
      }
      &.next{
        border-radius: 0px 6px 6px 0px;
        background: url(../assets/images/icons/chevron_right_gray800.svg) $color_white center no-repeat;
      }
      &:hover {
        opacity: .5;
      }
    }

    .term{
      width: 150px;
      margin-left: 11px;

      ::v-deep .el-input__inner {
        background: none;
        border: 1px solid $color_gray_400;
        border-radius: 6px;
        padding-left: 24px;
        color: $color_gray_800;
        font-weight: 500;
        font-size: 20px;
        height: 36px;
      }
    }
  }

  //WeekとMonthの切替タブ
  .calendar_tabs {
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color_gray_800;

    .tab {
      margin: 0 8px;
      cursor: pointer;
    }

    .active {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      background: $color_dark_blue;
      @include card_shadow;
      border-radius: 100px;
      color: $color_white;
    }
  }

  .mini_calendar {
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      margin: 0;
    }
    .btn_arrow {
      display: flex;
      border-radius: 6px;

      i {
        width: 24px;
        height: 24px;
        margin: 0;
        box-shadow: none;
        cursor: pointer;
        &.back{
          background: url(../assets/images/icons/chevron_left_gray800.svg) center no-repeat;
        }
        &.next{
          background: url(../assets/images/icons/chevron_right_gray800.svg) center no-repeat;
        }
        &:hover {
          opacity: .5;
        }
      }
    }

    .el-calendar {
      width: 100%;
      margin: 0 auto;
      background: none;
      border: none;

      ::v-deep table {
        box-shadow: none;
        background: none;
        border: none;
        th {
          height: 24px;
          font-weight: 600;
          font-size: 10px;
          line-height: 16px;
          color: $color_gray_600;
        }
        td {
          height: 28px;
          background: none;
          border: none;

          .el-calendar-day {
            font-weight: 600;
            font-size: 11px;
            line-height: 16px;
            color: $color_black;
            cursor: default;

            &:hover {
              background: none;
            }
          }

          &.prev, &.next {
            .el-calendar-day {
              color: $color_gray_600;
            }
          }

          span.date {
            display: flex;
            width: 21px;
            height: 21px;
            border-radius: 100%;
            align-items: center;
            justify-content: center;
            margin: 0 auto 1px;
          }

          &.is-today span.date {
            color: $color_white;
            background:$color_dark_blue;
          }

          span.dot {
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background: $color_primary_blue;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .search ::v-deep .el-table__header-wrapper {
    display: none;
  }
</style>

<style lang="scss">
  .el-dropdown-menu.el-popper.event_dropdown {
    padding: 0!important;
    margin-top: 4px;
    z-index: 10!important;
  }
</style>
