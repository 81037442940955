<template>
  <div class="new_reminder">
    <header>
      <h1>{{$t('Label.Reminder')}}</h1>
    </header>
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item :label="$t('Label.Date')" prop="reminderDate" class="mail_subject">
        <el-date-picker
          style="width:216px"
          v-model="form.reminderDate"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder=""
          @change.native="onDateChange"
        />
      </el-form-item>
      <el-form-item :label="$t('Label.Time')" prop="reminderTime" class="mail_subject">
        <el-time-picker
          style="width:216px"
          v-model="form.reminderTime"
          placeholder="Select time"
          format="HH:mm"
          value-format="HH:mm:00"
          @change.native="onTimeChange"
        />
      </el-form-item>
      <el-form-item :label="$t('Label.Title')" prop="reminderTitle" class="mail_subject">
        <el-input style="width:456px" type="text" v-model="form.reminderTitle" maxlength="100" show-word-limit />
      </el-form-item>
        
      <el-form-item :label="$t('Label.Free Text')" prop="reminderText" class="content">
        <el-input
          type="textarea"
          placeholder=""
          v-model="form.reminderText"
          maxlength="1000"
          show-word-limit
        />
      </el-form-item>
    </el-form>
    <div class="buttons">
      <div style="display: flex;justify-content: flex-end">
        <tw-button v-if="form.reminderId" type="secondary" size="medium" icon="trash" @click="remove">Delete</tw-button>
        <tw-button type="primary" size="medium" @click="post">Save</tw-button>
      </div>
    </div>
  </div>
</template>

<script>
import { $api } from '@/store/ApiClient';
import mixinValidation from '@/utils/mixinValidation.js';
import { onDateChange, onTimeChange } from '@/utils/searchUtil.js';
import { DELETE_FLG } from 'lib-tw-common';
import TwButton from '@/components/atoms/TwButton';

export default {
  name: 'TwNewReminder',
  mixins: [mixinValidation],
  props: {
    data: Object
  },
  components: {
    TwButton,
  },
  data() {
    return {
      form: {
        reminderId: null,
        reminderDate: null,
        reminderTime: null,
        reminderTitle: '',
        reminderText: '',
      },
      rules: {
        reminderDate: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty"],
            validator: this.onEventValidation, trigger: 'change',
            required: true,
          }
        ],
        reminderTime: [
          {
            lakeelMessageSourceFlg: true,
            validationId: [],
            validator: this.onEventValidation, trigger: 'change'
          }
        ],
        reminderTitle: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty", "tw-isMaxLength100"],
            validator: this.onEventValidation, trigger: 'blur',
            required: true,
          }
        ],
        reminderText: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isMaxLength1000"],
            validator: this.onEventValidation, trigger: 'blur'
          }
        ],
      },
    };
  },
  computed: {
  },
  created() {
    this.form = {
      ...this.data
    }
  },
  methods: {
    async post() {      
      if (await this.validationCheck('form')) {
        this.$store.commit('START_PROCESS');
        // bff_db_8 ダッシュボードリマインダー登録・更新・削除BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/dashboard/calendar-reminder',
          },
          data: {
            calendarReminder: {
              reminderId: this.form.reminderId,
              reminderDate: this.form.reminderDate,
              reminderTime: this.form.reminderTime,
              reminderTitle: this.form.reminderTitle,
              reminderText: this.form.reminderText,
              reminderDeleteFlg: DELETE_FLG.NOT_DELETED,
            }
          }
        }

        $api.request(params)
        .then(() => {
          this.$emit('update-calendar');
          this.$store.commit('END_PROCESS');
          setTimeout(() => {
            this.$store.dispatch('SHOW_COMPLETED');
          }, 500);
          this.$store.commit('SET_DRAWER', false);
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        });        
      } else {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
    remove() {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this data?')
        .then(() => {
          this.$store.commit('START_PROCESS');
          // bff_db_8 ダッシュボードリマインダー登録・更新・削除BFF
          const params = {
            lslConfig: {
              serviceCode: 'tw-transaction-bff-api',
              apiCode: 'post_/v1/dashboard/calendar-reminder',
            },
            data: {
              calendarReminder: {
                reminderId: this.form.reminderId,
                reminderDeleteFlg: DELETE_FLG.DELETED,
              }
            }
          }

          $api.request(params)
          .then(() => {
            this.$emit('update-calendar');
            this.$store.commit('END_PROCESS');
            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);
            this.$store.commit('SET_DRAWER', false);
          })
          .catch(err => {
            this.$store.commit('END_PROCESS');
            this.$store.dispatch('SHOW_ALERT', err.message);
          });
        });
    },
    onDateChange,
    onTimeChange,
  }
}
</script>

<style lang="scss" scoped>
  .new_reminder {
    width: 100%;
    height: 100%;
    position: relative;

    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 0 20px;
      border-bottom: 1px solid $color_gray_300;
      margin-bottom: 24px;

      h1 {
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        margin: 0;
      }
    }

    .el-form-item {
      display: flex;
      margin: 0;
      padding: 0 0 24px;

      ::v-deep textarea {
        width: 456px;
        resize: none;
        min-height: 390px!important;
        box-shadow: inset 2.5px 2.5px 5px rgba(170, 170, 204, 0.5);
      }
    }

    ::v-deep label.el-form-item__label {
      width: 120px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      display: inline-block;
      text-align: left;
      margin-right: 8px;
    }

    .buttons {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      padding: 40px 0;
      background: $color_white;
      margin-left: -20px;
      padding-right: 20px;
      width: calc(100% + 40px);
      z-index: 3;

      .tw_button {
        margin-left: 16px;
        margin-right: 0;
      }
    }

  }
</style>